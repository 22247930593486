@import '../../styles/variables.scss';

.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  user-select: none;
}

.loaderOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
}

.opacity {
  opacity: 0.7;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  text-align: center;

  .khunthongContainer {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 0;

    &.jumping {
      animation-name: jupming;
      animation-duration: 2s;
      animation-timing-function: ease;
    }
  }

  @keyframes jupming {
    0% {
      transform: scale(1, 1) translate(-50%, -50%);
    }
    10% {
      transform: scale(1.1, 0.9) translate(-50%, -50%);
    }
    30% {
      transform: scale(1, 1) translate(-50%, -100%);
    }
    45% {
      transform: scale(1.1, 0.9) translate(-50%, -50%);
    }
    50% {
      transform: scale(1, 1) translate(-50%, -50%);
    }
    100% {
      transform: scale(1, 1) translate(-50%, -50%);
    }
  }

  .descriptionContainer {
    transform: translate(0, 250%);
    .description {
      height: 24px;
      font-size: 18px;
      color: $secondary-text-color;
      font-weight: 1000;
      white-space: nowrap;
    }
  }

  .subDescriptionContainer {
    margin-top: 6px;
    .subDescription {
      height: 20px;
      font-size: 14px;
      color: $secondary-text-color;
      white-space: nowrap;
    }
  }
}

.ldsRing {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.ldsRing div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $secondary-color;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary-color $primary-color $primary-color $secondary-color;
}
.ldsRing div:nth-child(1) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  0% {
    transform: scale(1, 1);
  }

  // @for $i from 1 to 10 {
  //   .results div:nth-child(#{$i}) { animation-delay: $i * 0.25s; }
  // }
}

// .ldsKT img {
//   animation: zoominoutsinglefeatured 0.8s forwards;
// }

.ldsKT {
  margin: auto;
  display: block;
}

.ldsKT img {
  animation: scale 0.8s linear infinite;
  width: 40px;
}

@keyframes scale {
  0% {
    transform: scale(1, 1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
