@import "variables";

$breakpoints: (
  "sm": 540px,
  "md": 720px,
  "lg": 960px,
  "xl": 1140,
  "phone-super-small": 300px,
  "phone-extra-small": 350px,
  "phone-small": 360px,
  "phone-md": 370px,
  "phone": 400px,
  "phone-large": 414px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop-mini": 992px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
);

//extra small

$breakpointheights: (
  "phone-sm": 480px,
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type==max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin mq-height($height, $type: min) {
  @if map_has_key($breakpointheights, $height) {
    $height: map_get($breakpointheights, $height);
    $height: $height - 1px;
  }
  @media only screen and (#{$type}-height: $height) {
    @content;
  }
}

$shadow: #222;

@mixin shadow {
  border-bottom: 1px solid $gray100;
  -webkit-box-shadow: 0 4px 6px -6px $shadow;
  -moz-box-shadow: 0 4px 6px -6px $shadow;
  box-shadow: 0 4px 6px -6px $shadow;
}

@mixin buttonPrimary {
  border-color: $primary-color;
  background-color: $primary-color;
  color: $white;
}

@mixin buttonSecondary {
  border-color: $primary-color;
  background-color: $white;
  color: $primary-color;
}

@mixin buttonDefault {
  border-color: $secondary-color;
  background-color: $white;
  color: $secondary-color;
}

@mixin buttonDefaultWithBg {
  border-color: $secondary-color;
  background-color: $secondary-color;
  color: $white;
}

@mixin buttonLine {
  color: $white;
  background-color: $primary-color;
  border-color: $primary-color;
}

@mixin buttonLineNoBg {
  color: $primary-color;
  background-color: $white;
  border: 1px solid $primary-color;
}

@mixin buttonSm {
  min-width: 100px;
}

@mixin buttonMd {
  min-width: 200px;
}

@mixin buttonLg {
  min-width: 300px;
}

@mixin buttonFullWidth {
  width: 100%;
}

@mixin textHeader {
  font-size: 25px;
  height: 35px;
  font-weight: bold;
  font-synthesis: none;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: $default-text-color;
  @include mq("phone-extra-small") {
    font-size: 28px;
  }
  @include mq("phone") {
    font-size: 29px;
    height: 43px;
  }
}

@mixin textDefault($font-size: 16px, $color: $default-text-color) {
  font-size: $font-size;
  color: $color;
}

@mixin imgCrown {
  background-size: 100%;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin textError {
  font-size: 17px;
  font-weight: bold;
  font-synthesis: none;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.59;
  letter-spacing: normal;
  text-align: center;
  color: $secondary-text-color;
}

@mixin accountName {
  font-size: 14px;
  font-weight: bold;
  font-synthesis: none;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: $secondary-text-color;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include mq("phone-extra-small") {
    font-size: 15px;
    line-height: 1.6;
  }
  @include mq("phone") {
    font-size: 17px;
    line-height: 1.59;
  }
}

@mixin accountNo {
  font-size: 13px;
  font-weight: bold;
  font-synthesis: none;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: $secondary-text-color;
  margin-left: 5px;
  margin-top: -2px;
  @include mq("phone-extra-small") {
    font-size: 14px;
    line-height: 1.44;
  }
  @include mq("phone") {
    line-height: 1.25;
    font-size: 15px;
  }
}

@mixin iconImg($max-or-width, $width, $max-or-height, $height) {
  #{$max-or-width}: $width;
  #{$max-or-height}: $height;
}

// Needed !important, when from click back MUI Card style override the components style
@mixin bankInfoCard($have-border: false, $have-box-shadow: false) {
  height: 72px;
  border-radius: 14px !important ;
  @if $have-border {
    border: solid 1px #ebebeb !important;
    box-shadow: none !important;
  } @else if $have-box-shadow {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.17) !important;
    border: none !important;
  } @else {
    box-shadow: none;
    border: none;
  }
  background-color: $white;
}

@mixin bankInfoCardNoImportant($have-border: false, $have-box-shadow: false) {
  height: 72px;
  border-radius: 14px;
  @if $have-border {
    border: solid 1px #ebebeb;
    box-shadow: none;
  } @else if $have-box-shadow {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.17);
    border: none;
  } @else {
    box-shadow: none;
    border: none;
  }
  background-color: $white;
}

@mixin fadeinAnimation {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

@mixin hideText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin hideTextLines($lines: 2) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  word-break: break-word;
  word-wrap: break-word;
}
