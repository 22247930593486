@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.bottomStyle {
  @include mq("phone") {
    bottom: 8px;
  }
}

.bottomPadding {
  @include mq("phone-extra-small") {
    padding-top: 8px !important;
    padding-bottom: 10px; // ex. mi9
  }
  @include mq("phone") {
    padding-top: 10px !important;
    padding-bottom: 8px; // for iphoneX up
  }
}

.bottomBtn {
  z-index: 998;
  background-color: transparent;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  margin-top: auto;
  bottom: 0px;
  left: 0;
  right: 0;
  padding-top: 4px;
  @extend .bottomPadding;

  > .stickyWrap {
    text-align: left;
  }
  > .bottonWrap {
    text-align: center;
    .button {
      svg {
        font-size: 18px;
        @include mq("phone-extra-small") {
          font-size: 20px;
        }
        @include mq("phone") {
          font-size: 22px;
        }
      }
      font-size: 14px;
      font-weight: bolder;
      margin: 2% 0.5%;
      border-radius: 12px;
      box-shadow: none;
      border: 1px solid $secondary-color;
      overflow: hidden;
      white-space: nowrap;
      text-transform: capitalize;
      @include mq("phone-extra-small") {
        font-size: 15px;
        margin: 2% 1.5%;
        height: 48px;
      }
      @include mq("phone") {
        font-size: 16px;
        height: 52px;
      }

      &.backButton {
        width: 24%;
        float: left;
        text-align: left;
        left: 8px;
        bottom: 8px;
        position: absolute;
        @include mq("phone-extra-small") {
          width: 25%;
        }
      }

      &.nextButton {
        width: 66%;
        float: right;
        text-align: right;
        right: 8px;
        bottom: 8px;
        position: absolute;
        text-transform: none;
        @include mq("phone-extra-small") {
          width: 65%;
        }
      }

      &.leftButton {
        width: 45%;
        float: left;
        text-align: left;
        left: 8px;
        bottom: 8px;
        position: absolute;
      }

      &.rightButton {
        width: 45%;
        float: right;
        text-align: right;
        right: 8px;
        bottom: 8px;
        position: absolute;
      }

      &.mainButton {
        width: 95%;
      }

      &.primary {
        color: $white;
        background-color: $primary-color;
        border: 1px solid $primary-color;
      }

      &.noBankAccount {
        color: $white;
        background-color: #f4c859;
        border: 1px solid #f4c859;
      }

      &.primaryOutlined {
        color: $primary-color;
        background-color: $white;
        border: 1px solid $primary-color;
      }

      &.secondary {
        color: $default-text-color;
        background-color: $white;
        border: 1px solid $secondary-color;
      }

      &.disabled {
        color: $white !important;
        background-color: $disabled-color;
        border: 1px solid $secondary-color;
      }

      &.default {
        color: $default-text-color;
        background-color: $white;
        border: 1px solid $secondary-color;
      }
    }

    .rightImage {
      float: right;
      bottom: 0;
      right: 0;
      display: block;
      position: absolute;
      margin-bottom: -8px;
      img {
        max-width: 275px;
        max-height: 147px;
      }
    }
  }
}
