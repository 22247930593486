$primary-color: #00c700;
$primary-color-hightlight: #00c700;
$secondary-color: #d8d8d8;
$secondary-color-hightlight: #d8d8d8;
$primary-theme-color: #6dccbf;
$secondary-theme-color: #42bcac;
$yellow-theme-color: #ebb01f;
$error-color: #f44336;
$btn-cancel-color: #e17059;
$white: #ffffff;
$black: #000000;
$gray50: #f5f5f5;
$gray100: #e0e0e0;
$gray200: #c4c4c4;
$gray300: #828282;
$gray400: #4f4f4f;
$gray700: #363d47;

$text-gray: #737373;
$disabled-color: rgb(216, 216, 216);
$gray-color: #d8d8d8;

/* background color */
$background-page: #f5f6fa;

$default-text-color: #444444;
$secondary-text-color: #4d4d4d;
$hightlight-text-color: #808080;
$secondary-hightlight-text-color: #999999;
$light-grey: #dbdbdb;
$secondary-light-grey: #b3b3b3;
$link-green: #5dc65f;

$orange-text-color: $btn-cancel-color;
$green-text-color: $link-green;
$border-green-color: $primary-theme-color;
$red-text-color: #a81019;
$yellow-text-color: #f4c859;
$light-yellow-color: #fff6d7;
$dark-green: #3d7678;
$dark-green-bg: #62bbac;

$fadeAnimeTime: 200ms;
$moveAnimeTime: 200ms;
$slideLineAnimeTime: 400ms;
