@import url("https://fonts.googleapis.com/css?family=Montserrat");
$font-path: "/fonts/";

// "Kurious_looped";
@font-face {
  font-family: "Kurious_looped";
  src: url("#{$font-path}Kurious_looped/kurious_looped-light.eot");
  src: url("#{$font-path}Kurious_looped/kurious_looped-light.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Kurious_looped/kurious_looped-light.woff") format("woff"),
    url("#{$font-path}Kurious_looped/kurious_looped-light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Kurious_looped";
  src: url("#{$font-path}Kurious_looped/kurious_looped-medium.eot");
  src: url("#{$font-path}Kurious_looped/kurious_looped-medium.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Kurious_looped/kurious_looped-medium.woff") format("woff"),
    url("#{$font-path}Kurious_looped/kurious_looped-medium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Kurious_looped";
  src: url("#{$font-path}Kurious_looped/kurious_looped-semibold.eot");
  src: url("#{$font-path}Kurious_looped/kurious_looped-semibold.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}Kurious_looped/kurious_looped-semibold.woff") format("woff"),
    url("#{$font-path}Kurious_looped/kurious_looped-semibold.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}


// "Sarabun";
@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-Bold.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-BoldItalic.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-ExtraBold.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-ExtraBoldItalic.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-ExtraLight.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-ExtraLightItalic.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-Italic.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-Light.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-LightItalic.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-Medium.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-MediumItalic.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-Regular.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-SemiBold.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-SemiBoldItalic.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-Thin.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Sarabun";
  src: url("#{$font-path}Sarabun/Sarabun-ThinItalic.ttf");
  src: url("#{$font-path}Sarabun/Sarabun-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

// NotoSansThai
@font-face {
  font-family: "NotoSansThai";
  src: url("#{$font-path}NotoSansThai/NotoSansThai-Light.eot");
  src: url("#{$font-path}NotoSansThai/NotoSansThai-Light.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Light.woff") format("woff"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Light.ttf") format("truetype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Light.svg#NotoSansThai-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansThai";
  src: url("#{$font-path}NotoSansThai/NotoSansThai-Regular.eot");
  src: url("#{$font-path}NotoSansThai/NotoSansThai-Regular.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Regular.woff") format("woff"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Regular.ttf") format("truetype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Regular.svg#NotoSansThai-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansThai";
  src: url("#{$font-path}NotoSansThai/NotoSansThai-Regular.eot");
  src: url("#{$font-path}NotoSansThai/NotoSansThai-Regular.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Regular.woff") format("woff"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Regular.ttf") format("truetype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Regular.svg#NotoSansThai-Regular") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansThai";
  src: url("#{$font-path}NotoSansThai/NotoSansThai-Medium.eot");
  src: url("#{$font-path}NotoSansThai/NotoSansThai-Medium.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Medium.woff") format("woff"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Medium.ttf") format("truetype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-Medium.svg#NotoSansThai-Medium") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NotoSansThai";
  src: url("#{$font-path}NotoSansThai/NotoSansThai-SemiBold.eot");
  src: url("#{$font-path}NotoSansThai/NotoSansThai-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-SemiBold.woff") format("woff"),
    url("#{$font-path}NotoSansThai/NotoSansThai-SemiBold.ttf") format("truetype"),
    url("#{$font-path}NotoSansThai/NotoSansThai-SemiBold.svg#NotoSansThai-SemiBold") format("svg");
  font-weight: 800;
  font-style: normal;
}

// NotoSans
@font-face {
  font-family: "NotoSans";
  src: url("#{$font-path}NotoSans/NotoSans-Light.eot");
  src: url("#{$font-path}NotoSans/NotoSans-Light.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSans/NotoSans-Light.woff") format("woff"),
    url("#{$font-path}NotoSans/NotoSans-Light.ttf") format("truetype"),
    url("#{$font-path}NotoSans/NotoSans-Light.svg#NotoSans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("#{$font-path}NotoSans/NotoSans-Regular.eot");
  src: url("#{$font-path}NotoSans/NotoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSans/NotoSans-Regular.woff") format("woff"),
    url("#{$font-path}NotoSans/NotoSans-Regular.ttf") format("truetype"),
    url("#{$font-path}NotoSans/NotoSans-Regular.svg#NotoSans-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("#{$font-path}NotoSans/NotoSans-Regular.eot");
  src: url("#{$font-path}NotoSans/NotoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSans/NotoSans-Regular.woff") format("woff"),
    url("#{$font-path}NotoSans/NotoSans-Regular.ttf") format("truetype"),
    url("#{$font-path}NotoSans/NotoSans-Regular.svg#NotoSans-Regular") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("#{$font-path}NotoSans/NotoSans-Medium.eot");
  src: url("#{$font-path}NotoSans/NotoSans-Medium.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSans/NotoSans-Medium.woff") format("woff"),
    url("#{$font-path}NotoSans/NotoSans-Medium.ttf") format("truetype"),
    url("#{$font-path}NotoSans/NotoSans-Medium.svg#NotoSans-Medium") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("#{$font-path}NotoSans/NotoSans-SemiBold.eot");
  src: url("#{$font-path}NotoSans/NotoSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("#{$font-path}NotoSans/NotoSans-SemiBold.woff") format("woff"),
    url("#{$font-path}NotoSans/NotoSans-SemiBold.ttf") format("truetype"),
    url("#{$font-path}NotoSans/NotoSans-SemiBold.svg#NotoSans-SemiBold") format("svg");
  font-weight: 800;
  font-style: normal;
}

@mixin font-default {
  font-family: Kurious_looped, Sarabun ,NotoSansThai, NotoSans, "Helvetica Neue", Helvetica, Thonburi, Arial, sans-serif;
}
