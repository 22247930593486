@import '../../common/styles/mixins.scss';
@import '../../common/styles/variables.scss';

.displaySuccessText,
.iframeText {
  margin-top: 0px !important;
}

.flexImage {
  display: flex;
  justify-content: center;

  > img {
    margin: 5px;
  }

  > img.logokhunthong {
    border-radius: 12px;
    background-color: $primary-theme-color;
    width: 55px !important;
    height: 55px !important;
    @include mq('phone') {
      width: 68px !important;
      height: 68px !important;
    }
  }
}
