@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.swal2-container.swal2-shown {
  overflow-y: auto;
  z-index: 9999;
}

.swalBot.swal2-popup {
  width: 80%;
  border-radius: 18px;
  padding: 10px 10px 5px 10px;

  #swal2-content {
    @include textDefault;
    font-size: 14px;
    @include mq("phone-extra-small") {
      font-size: 15px;
    }
    padding-bottom: 14px;
    border-bottom: 1px solid $secondary-color;
  }

  .swal2-actions {
    margin: 0;
    justify-content: space-around;
  }

  .swalBtn2 {
    width: 50%;
  }

  .swalBtn {
    @include textDefault;
    font-size: 16px;
    font-weight: bold;
    background-color: transparent;
    color: #00a84e;
    border: 1px solid $white;
    padding: 10px 0px 10px 0px;
    outline: none;
    width: 100%;

    &:focus {
      user-select: none;
    }

    &.buttonCancel {
      border-right: 1px solid $secondary-color;
    }
  }

  .swalBtn2 {
    @extend .swalBtn;
    width: 50%;
    &:focus {
      user-select: none;
    }

    &.buttonCancel {
      color: $hightlight-text-color;
      border-right: 1px solid white; // $secondary-color;
    }
    &.buttonConfirm {
      color: $primary-color;
    }
    &.buttonConfirmDelete {
      color: $btn-cancel-color;
    }
  }
  .swal2-icon {
    &.swal2-warning,
    &.swal2-question,
    &.swal2-info,
    &.swal2-error,
    &.swal2-success {
      border-color: $secondary-color;
      color: $secondary-color;
      font-size: 0.6em;
      margin-top: 1.6em;
      margin-bottom: 1.2em;
    }

    span.swal2-x-mark-line-left,
    span.swal2-x-mark-line-right {
      background-color: $secondary-color;
    }
  }

  .swal2-title {
    display: none !important;
  }
}
