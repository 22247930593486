@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.error {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 9999;

  .bottomButton {
    position: fixed;
  }

  .content {
    position: relative;
    top: 45%;
    transform: translateY(-50%);
    text-align: center;
    margin: 0 auto;
    width: fit-content;

    img {
      width: 80px;
    }

    > .errmsg,
    > .errcode {
      margin-top: 24px;
      width: 230px;
      @include mq("phone") {
        width: 250px;
      }
    }

    > .errmsg span {
      @include textError;
      white-space: initial;
    }

    > .errcode span {
      @include textHeader;
      line-height: 1em;
      white-space: initial;
    }

    > .deviceInfo span {
      @include textError;
      white-space: initial;
      font-weight: normal;
      font-size: 10px;
    }
  }
}
